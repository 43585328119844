class Solutions extends elementorModules.frontend.handlers.Base {
  constructor($element: JQuery, args: any) {
    super($element, args);
  }

  getDefaultSettings() {
    return {
      selectors: {
        content: ".solutions-content",
        link: ".solutions-link",
        images: ".solutions-images",
      },
    };
  }

  getDefaultElements() {
    const selectors = this.getSettings("selectors");
    return {
      $content: this.$element.find(selectors.content),
      $images: this.$element.find(selectors.images),
    };
  }

  bindEvents(): void {
    // on solutions-link hover, set --active-colour to the solutions-content
    const links = this.elements.$content[0].querySelectorAll(".solutions-link");

    const deactivateLink = (index: number) => {
      this.elements.$images[0]
        .querySelectorAll(".solutions-image")
        [index].classList.remove("active");

      this.elements.$images[0]
        .querySelectorAll(".circle .icon")
        [index].classList.remove("active");
    };

    links.forEach((link: HTMLElement, index: number) => {
      const activateLink = (e: Event) => {
        const target = e.currentTarget as HTMLElement;

        this.elements.$content[0].style.setProperty(
          "--solutions-active-colour",
          `var(--solutions-colour-${index})`
        );

        this.elements.$images[0]
          .querySelectorAll(".solutions-image")
          [index].classList.add("active");

        this.elements.$images[0]
          .querySelectorAll(".circle .icon")
          [index].classList.add("active");
      };

      const deactivateLinkEvent = (e: Event) => {
        deactivateLink(index);
      };

      link.addEventListener("mouseenter", activateLink);
      link.addEventListener("mouseleave", deactivateLinkEvent);
      link.addEventListener("touchstart", activateLink, { passive: true });
      link.addEventListener("touchend", deactivateLinkEvent, { passive: true });
    });

    window.addEventListener("pageshow", (event) => {
      links.forEach((_link: any, index: number) => {
        deactivateLink(index);
      });
    });

    window.addEventListener("popstate", (event) => {
      links.forEach((_link: any, index: number) => {
        deactivateLink(index);
      });
    });
  }
}

/**
 * Register JS Handler for the Test Widget
 *
 * When Elementor frontend was initiated, and the widget is ready, register the widget
 * JS handler.
 */
window.addEventListener("elementor/frontend/init", () => {
  elementorFrontend.elementsHandler.attachHandler(
    "gravitas_solutions",
    Solutions
  );
});
